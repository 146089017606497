import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import {
  TriageRecommendations,
  Typography,
  Wrapper,
  Loader,
  translate,
  TriageTestReportV3
} from "preventx-storybook";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTriageHistoryError,
  getTriageReport
} from "../../store/triageReport/actionCreators";
import { AuthenticateTemplate } from "../AuthenticateTemplate";
import { getserviceUserReference } from "../../store/serviceuserreference/logic";
import { gettriageHeader, gettriageHeaderReset } from "../../store/triageReport/triageheader/logic";

export const TriageReport = () => {
  const { t } = translate();
  const { QuestionnaireReference, serviceuserReferenceId } = useParams();
  const dispatch = useDispatch();
  const serviceUserReferenceState = useSelector(
    (state) => state.serviceUserReferenceReducer
  );
  const triageHeaderState = useSelector(
    (state) => state.triageHeaderReducer
  );

  useEffect(() => {
    if (QuestionnaireReference) {
      dispatch(
        getTriageReport({
          QuestionnaireReference,
          serviceuserReferenceId
        })
      );

      dispatch(
        gettriageHeader({
          QuestionnaireReference,
          serviceuserReferenceId
        })
      );
    }
    dispatch(
      getserviceUserReference({
        serviceUserReference: serviceuserReferenceId,
        profile: "UserDetails"
      })
    );
    return () => {
      dispatch(clearTriageHistoryError());
      dispatch(gettriageHeaderReset());
    };
  }, [QuestionnaireReference]);

  const { triageData, loading, triageHistoryError } = useSelector(
    (state) => state.triageReportReducer
  );

  return (
    <>
      <AuthenticatedTemplate>
        <Wrapper className="triageReport  sb-page__content">
          <Loader
            loading={loading || serviceUserReferenceState?.loading}
            errorCode={triageHistoryError?.code}
            errorMessage={{
              404: "Triage-Not-Found"
            }}
            error={triageHistoryError?.isError}
            height="70vh">
            <Wrapper className="triageReport__title">
              <Typography
                className="triageReport__text"
                variant="h2"
                translate={false}>
                {`${t("Triage-Title")} ${serviceUserReferenceState?.data?.FirstName || "NA"
                  } ${serviceUserReferenceState?.data?.LastName || ""}`}
              </Typography>
            </Wrapper>
            <Wrapper className="triageReport__container">
              <TriageTestReportV3
                inputData={triageData}
                headerDetails={triageHeaderState?.data} />
              <TriageRecommendations inputData={triageData} />
            </Wrapper>
          </Loader>
        </Wrapper>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticateTemplate />
      </UnauthenticatedTemplate>
    </>
  );
};
